(<template>
  <choose-name />
</template>)

<script>
  import WizardChooseName from '@/components/wizard_components/WizardChooseName';

  export default {
    components: {
      'choose-name': WizardChooseName
    }
  };
</script>
